import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { GoogleIcon } from '@icon/SocialIcons/GoogleIcon';
import { cmsLogout, login as myAdminLogin, sendFailedLogin } from '@services/authenticationPublicService';
import { useAtom } from 'jotai';
import { loginAtom, logoutAtom } from '@atoms/authentication';
import { NotificationContext } from '@contexts/NotificationContext';
import { getUserData } from '@services/googlePublicService';
import { logError } from '@helpers/errors';

const classes = {
    submit: {
        margin: '4% 0 2%',
    },
    googleIcon: {
        marginRight: '1rem',
    },
};

//TODO: See what errors can be returned from google login and map them to a friendly message
// function getErrorMessage(error) {
//     switch (error.error || error.message || error) {
//         case 'popup_blocked_by_browser':
//             return 'Google login dialog blocked by popup blocker. Disable popup blockers for this site.';
//         case 'popup_closed_by_user':
//             return 'Google login dialog closed. Login failed.';
//         case 'gapi is not defined':
//             return 'Google authentication not initialized. Login failed.';
//         case 'idpiframe_initialization_failed':
//             return error.details;
//         default:
//             return 'Login failed.';
//     }
// }

export const GoogleLogin = (): JSX.Element => {
    const notificationContext = useContext(NotificationContext);
    const {
        myAdminBaseApiUrl,
        authenticationSettings: { myAdminSource },
    } = useStaticValues();
    const [, userLogin] = useAtom(loginAtom);
    const [, userLogout] = useAtom(logoutAtom);

    const executeGoogleLogin = useGoogleLogin({
        flow: 'implicit',
        scope: 'profile email openid',
        onSuccess: (tokenResponse) => {
            const accessToken = tokenResponse.access_token;
            getUserData(accessToken)
                .then((userData) => {
                    myAdminLogin(myAdminBaseApiUrl, myAdminSource, userData, accessToken)
                        .then((loginResult) => {
                            userLogin(loginResult);
                            notificationContext.show(`Logged in as: ${loginResult.apiUser.name}`, 'success');
                        })
                        .catch((error) => {
                            cmsLogout().catch((error) => logError(error, 'executing CMS logout'));
                            userLogout();
                            sendFailedLogin(userData.email).catch((error) =>
                                logError(error, 'sending failed login request to api')
                            );
                            console.error('Error occurred logging in.', error);
                            //TODO: Update mapping of error messages (see todo above)
                            notificationContext.show('Login failed.', 'error');
                        });
                })
                .catch((error) => {
                    logError(error, 'getting user data');
                    notificationContext.show('Login failed. See console for details.', 'error');
                });
        },
        onError: (error) => {
            console.error(`GoogleLogin:onError: ${error}`);
        },
        onNonOAuthError: (error) => {
            console.error(`GoogleLogin:onNonOAuthError: ${error}`);
        },
    });

    const login = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        executeGoogleLogin();
    };

    return (
        <Button type='submit' fullWidth variant='outlined' css={classes.submit} onClick={login}>
            <GoogleIcon css={classes.googleIcon} />
            Login with Google
        </Button>
    );
};
