import { AuthenticatedUserInfo } from '@models/user';
import { httpGet } from './apiService';

export async function getUserData(accessToken: string): Promise<AuthenticatedUserInfo> {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);

    const response = await (
        await httpGet('https://www.googleapis.com/oauth2/v3/userinfo', headers, null, false, false)
    ).json();

    return {
        firstName: response.given_name,
        lastName: response.family_name,
        fullName: response.name,
        imageUrl: response.picture,
        email: response.email,
    };
}
