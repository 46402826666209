import React, { useContext, useState, useEffect } from 'react';
import { Grid, CssBaseline, Avatar, Button } from '@mui/material';
import { NotificationContext } from '@contexts/NotificationContext';
import { deepCopyObject } from '@helpers/editorFormHelper';
import { sendFailedLogin } from '@services/authenticationPublicService';
import { v2Colors } from '@web-for-marketing/react-ui';
import { LockIcon } from '@icon/LockIcon';
import { useAtom } from 'jotai';
import { isAuthenticatedAtom } from '@atoms/authentication';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { Text, Input } from '@web-for-marketing/react-ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@components/AdminComponents/Login/GoogleLogin';
import { SectionContainer } from '@components/SectionComponents/SectionContainer';

const classes = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: '1rem',
        border: `2px solid ${v2Colors.core.innovation}`,
        background: '#FFFFFF',
    },
    form: {
        marginTop: '1rem',
        width: '50%',
    },
    submit: {
        margin: '4% 0 2%',
    },
    login: {
        color: v2Colors.core.innovation,
        margin: 'unset',
    },
    inputSpacing: {
        marginTop: '1.6rem',
        marginBottom: '0.8rem',
    },
} as const;

const clearedInput = { username: '', password: '', isGoogleLogin: false };

export default function AdminLogin(): JSX.Element {
    const notificationContext = useContext(NotificationContext);
    const [loginData, setLoginData] = useState(clearedInput);
    const {
        authenticationSettings: { oAuthClientId },
    } = useStaticValues();
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);

    const executeUserPassLogin = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault();
        await sendFailedLogin(loginData.username);
        notificationContext.show('Login failed.', 'error');
    };

    useEffect(() => {
        if (isAuthenticated) {
            location.href = `${location.origin}/admin/dashboard/`;
        }
    }, [isAuthenticated]);

    function onInputChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: 'username' | 'password'
    ): void {
        const newLoginData = deepCopyObject(loginData);
        newLoginData[type] = event.target.value;

        if (type === 'username') {
            newLoginData.isGoogleLogin = event.target.value.toLowerCase().includes('@geotab.com');
        }

        setLoginData(newLoginData);
    }

    return (
        <SectionContainer>
            <Grid component='main'>
                <CssBaseline />
                <div css={classes.paper}>
                    <Avatar css={classes.avatar}>
                        <LockIcon color={v2Colors.core.innovation} />
                    </Avatar>
                    <Text component='h1' variant='body1' css={classes.login}>
                        Login
                    </Text>
                    <form css={classes.form} noValidate>
                        <Input
                            required
                            placeholder='Enter an email&hellip;'
                            shrinkLabel
                            id='username'
                            label='Email'
                            type='email'
                            onChange={(event) => onInputChange(event, 'username')}
                            css={classes.inputSpacing}
                        />
                        {loginData.isGoogleLogin ? (
                            <GoogleOAuthProvider clientId={oAuthClientId}>
                                <GoogleLogin />
                            </GoogleOAuthProvider>
                        ) : (
                            <>
                                <Input
                                    required
                                    shrinkLabel
                                    placeholder='Enter a password&hellip;'
                                    label='Password'
                                    type='password'
                                    id='password'
                                    onChange={(event) => {
                                        onInputChange(event, 'password');
                                    }}
                                    css={classes.inputSpacing}
                                />
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    css={classes.submit}
                                    onClick={(e) => {
                                        executeUserPassLogin(e).catch((error) => console.error(error));
                                    }}
                                >
                                    Log in
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </Grid>
        </SectionContainer>
    );
}
