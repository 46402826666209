import { v2Colors } from '@web-for-marketing/react-ui';
import React from 'react';

export function LockIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='24'
            height='26'
            viewBox='0 0 24 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            color={v2Colors.core.slate}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            stroke='currentColor'
            {...props}
        >
            <path d='M20.5 12.5C20.5 11.3954 19.6046 10.5 18.5 10.5H5.5C4.39543 10.5 3.5 11.3954 3.5 12.5V22.5C3.5 23.6046 4.39543 24.5 5.5 24.5H18.5C19.6046 24.5 20.5 23.6046 20.5 22.5V12.5Z' />
            <path d='M6.5 7C6.5 3.96243 8.96243 1.5 12 1.5C15.0376 1.5 17.5 3.96243 17.5 7V10.5H6.5V7Z' />
            <path d='M12 18.75C12.6904 18.75 13.25 18.1904 13.25 17.5C13.25 16.8096 12.6904 16.25 12 16.25C11.3096 16.25 10.75 16.8096 10.75 17.5C10.75 18.1904 11.3096 18.75 12 18.75Z' />
        </svg>
    );
}
